import React from "react";
import {
	Header,
	Container,
	Accordion,
	Button,
	Divider,
	Title,
	Anchor,
	Text,
} from "@mantine/core";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { IconChevronLeft } from "@tabler/icons";
import { IconRefresh } from "@tabler/icons";

export default function Toc() {
	let navigate = useNavigate();

	const { currentUser } = useAuth();

	return (
		<>
			{!currentUser && (
				<Header
					height={60}
					p="xs"
					sx={{ display: "flex", alignItems: "center" }}
				>
					<Button
						onClick={() => navigate("/")}
						variant="subtle"
						leftIcon={<IconChevronLeft size={14} />}
					>
						Login
					</Button>
					<Divider orientation="vertical" ml={10} mr={16} />
					<Title order={3}>Terms of service</Title>
				</Header>
			)}
			<Container mt={"20px"}>
				<Title>Terms of Service - 2Solar Teamleader Focus plugin</Title>
				<Title order={2}>1. Key terms</Title>
				<p>"Administrator" </p>
				<p>
					The User responsible for the Administrator account and who has the
					most extensive usage rights on the Tool. More information can be found
					in this article in the Support Centre.
				</p>
				<p>"Administrator account" </p>
				<p>
					The User account that can only be accessed and used by the
					Administrator.
				</p>
				<p>"Affiliated company" </p>
				<p>
					The company associated with a Party, as defined in Article 1:20 of the
					Belgian Companies and Associations Code.
				</p>
				<p>"Agreement" </p>
				<p>
					The agreement that the Parties enter into with respect to the Services
					when the Customer accepts COACHALL's Terms of Service in writing or
					electronically and that applies to the commercial relationship between
					the Parties. The Agreement also includes the DPA attached to these
					Terms of Service.
				</p>
				<p>"Communication" </p>
				<p>
					Any non-commercial communication from COACHALL to the Customer (and,
					where applicable, its Users) about the Services via its Website,
					email, in-app messages and/or any other appropriate means of
					communication.
				</p>
				<p>"Customer" </p>
				<p>
					Any natural or legal person who enters into an Agreement with
					COACHALL.{" "}
				</p>
				<p>
					As the Tool is intended for internal business purposes only, each
					Customer is considered a "company" within the meaning of the Belgian
					Economic Code and therefore cannot be a "consumer" in its commercial
					relationship with COACHALL.
				</p>
				<p>"Customer Account" </p>
				<p>All Customer User Accounts and the Customer Data they contain.</p>
				<p>"Customer Data" </p>
				<p>
					All content, information and data - including personal data - relating
					to third parties other than the Customer and its Users (including, but
					not limited to: prospects, business partners, clients and customers of
					the Customer) and which the Customer and/or its Users have entered and
					uploaded in the Customer Account when using the Services.
				</p>
				<p>"Customer Personal Data". </p>
				<p>
					All Customer Data that are considered "personal data" within the
					meaning of the Privacy Act.
				</p>
				<p>"Documentation" </p>
				<p>
					Any documentation provided by COACHALL on the Services as well as on
					the Website, including all documentation, tutorials and other
					materials available on the Website.
				</p>
				<p>"DPA" </p>
				<p>COACHALL's data processing agreement as found at</p>
				<a href="https://www.coachall.be/privacy-policy">
					https://www.coachall.be/privacy-policy.
				</a>
				<p>"Privacy Statement" </p>
				<p>
					COACHALL's privacy statement as found at
					https://www.COACHALL.eu/privacy.
				</p>
				<p>"Privacy Law" </p>
				<p>
					Refers collectively to (i) the General Data Protection Regulation of
					27 April 2016 (the Regulation of the European Parliament and of the
					Council on the protection of individuals with regard to the processing
					of personal data and on the free movement of such data or "AVG") and
					(ii) all Belgian implementing laws in this regard.
				</p>
				<p>"Party" </p>
				<p>
					Any party to the Agreement, whether COACHALL or the Customer. COACHALL
					and the Customer are collectively referred to as the "Parties".
				</p>
				<p>"Services" </p>
				<p>
					The services provided by COACHALL to the Customer under the Agreement,
					including but not limited to: the Software License and, where
					applicable, the Introductory Services.
				</p>
				<p>"Introductory Services" </p>
				<p>
					The services offered and performed by COACHALL's staff in relation to
					the creation and roll-out of the Customer Account so that the Software
					License can be used correctly and effectively.
				</p>
				<p>"Software License" </p>
				<p>
					The software license for the Tool and all associated usage rights that
					COACHALL makes available to the Customer and its Users.
				</p>
				<p>"License Fee" </p>
				<p>
					The periodic amounts paid by the Customer for the use of the Software
					License, mainly based on the selected Package and the number of User
					Accounts.
				</p>
				<p>"Term" </p>
				<p>
					The initial or extended duration - i.e. yearly - during which the
					Software License is granted to the Customer and its Users.
				</p>
				<p>"Renewal Date" </p>
				<p>
					The date after the expiry of the previous Term on which the Software
					License is automatically renewed for an additional Term.
				</p>
				<p>"Tool" </p>
				<p>
					The web-based and mobile Software as a Service (SaaS) application
					"COACHALL Focus" owned, developed and maintained by COACHALL and its
					Affiliates.
				</p>
				<Title order={2}>2. The Agreement</Title>
				<Title order={3}>2.1 Consideration</Title>
				<p>
					COACHALL offers a tool to synchronize data, in the form of a web
					application, in order to make the company more effective in dealing
					with its customers.
				</p>
				<Title order={3}>2.2 Conclusion of the Agreement </Title>
				<p>
					The Customer expressly acknowledges that any acceptance of the Terms
					of Service by electronic means gives rise to a valid Agreement. This
					is fully in accordance with the provisions of the Belgian Economic
					Code. Within the legally permitted limitations, COACHALL can use all
					available electronic files to prove the existence of the Agreement.
				</p>
				<p>
					Anyone entering into this Agreement on behalf of a legal entity
					declares and warrants that they are legally authorised to bind this
					legal entity to these Terms of Service.
				</p>
				<p>
					COACHALL is entitled at any time to request additional information
					about the signatory and the (potential) Customer. COACHALL is entitled
					to terminate the Agreement or suspend performance of the Services in
					the event of (i) doubts about the identity of the signatory, (ii)
					doubts about the creditworthiness of the (potential) Customer and
					(iii) indications that the (potential) Customer intends to resell the
					Services without COACHALL's explicit consent. Such termination of the
					Agreement or suspension of its performance will not entitle the other
					party to any form of compensation or damages under any circumstances.
				</p>
				<p>
					<i>Delivery</i>
				</p>
				<p>
					After paying the invoice in full, the Customer will receive access to
					the Tool. Providing the Client with an Administrator account shall
					constitute delivery of the Software License.
				</p>
				<p>
					Upon delivery of the Software License, the Customer is required to
					perform an initial check to determine whether the Software License
					delivered corresponds to the order. This includes the functionalities.
				</p>
				<p>
					Customer is obliged to notify COACHALL of any non-conformity within 48
					hours of delivery via the contact page. If the Customer has not
					notified COACHALL within 48 hours, it will be deemed to have approved
					and accepted the delivery.
				</p>
				<Title order={3}>2.3 No right of withdrawal </Title>
				<p>
					Pursuant to the Belgian Economic Code or WER, every consumer (i.e.
					every natural person who is acting for purposes that are outside his
					trade, business, craft or profession) has a right of withdrawal with
					respect to products and/or services purchased via the Internet, by
					e-mail or by telephone.
				</p>
				<p>
					Since the Services are intended exclusively for internal business
					purposes, every Customer in its business relationship with COACHALL is
					considered a "company" within the meaning of the Belgian Economic
					Code. Consequently, our Customers cannot exercise a right of
					withdrawal under any circumstances, neither with regard to the
					Software License.
				</p>
				<Title order={3}>2.4 Scope of application </Title>
				<p>
					The commercial relationship between COACHALL and the Customer is
					exclusively governed by these Terms of Service. This Agreement
					replaces and prevails over any previous agreements in connection with
					the Services that may have existed between the Parties. These Terms of
					Service always take precedence over the Customer's general terms and
					conditions.
				</p>
				<p>
					<b>2.5 Amendments to the Agreement</b>
				</p>
				<p>
					COACHALL reserves the right to change its terms of service, prices and
					Services at any time in the future. COACHALL undertakes to notify the
					Customer of these changes by way of a Communication.
				</p>

				<p>
					Non-substantial changes will take effect as soon as the Customer is
					informed of them.
				</p>
				<p>
					Substantial changes will only take effect after the reasonable period
					which COACHALL has communicated to the Customer has elapsed.
				</p>
				<p>
					Only in the event of substantial changes to the Contract (such as a
					price increase, see Clause 3.3) does the Customer have the opportunity
					to object and, if the Parties do not find an equitable solution, to
					terminate the Contract (see Clause 9.1).
				</p>
				<Title order={2}>3. Price </Title>
				<Title order={3}>3.1 Commercial offers </Title>
				<p>
					Unless expressly stated otherwise, all promotions, commercial offers,
					discounts and announcements communicated by COACHALL via its Website,
					by e-mail or in any other form (including, inter alia, catalogues,
					brochures, newsletters, folders and other advertising announcements)
					are entirely without obligation and may only be regarded by the
					Customer or potential Customer as an invitation to order the Services.
				</p>
				<p>
					Any commercial discounts on the usual prices that are granted verbally
					(e.g. by telephone) must be confirmed in writing (e.g. on the
					corresponding invoice) in order to be valid.
				</p>
				<p>
					The (potential) Customer acknowledges that discounts only apply in
					accordance with the guidelines and conditions expressly stated in this
					regard. Such discounts shall be deemed to be granted once for the
					initial Term or the specified duration and thus shall not
					automatically apply to subsequent (similar) orders. Any other practice
					is to be regarded as a commercial gesture by COACHALL and only applies
					as long as it is not revoked by COACHALL. The (potential) Customer
					acknowledges that discounts (and other promotional gifts) cannot be
					combined, are personal by nature and can never give rise to any vested
					right.
				</p>
				<Title order={3}>3.2 Prices </Title>
				<p>
					<i>General</i>
				</p>
				<p>
					All prices are exclusive of VAT and/or other taxes and (local) levies.
					All prices are in EURO and also payable in EURO.
				</p>
				<p>
					<b>3.3 Price changes</b>
				</p>
				<p>
					<i>General</i>
				</p>
				<p>
					Under no circumstances does COACHALL warrant that it will maintain the
					prices for any of its Services. COACHALL may adjust the price of one
					or more of its Services at any time.
				</p>
				<p>
					In the event of a price increase which affects existing customers,
					COACHALL undertakes to inform its customers by way of a Communication
					at least one (1) month before the new prices apply.
				</p>
				<p>
					<b>3.4 License fees</b>
				</p>
				<p>
					Without prejudice to the provisions on indexation, an increase in a
					Customer's License Fee commencing during a current Term is regarded as
					a substantial amendment to the Agreement. Therefore, the Customer will
					have the possibility to terminate the Agreement. The Customer must
					send this termination in writing to COACHALL before the new License
					Fees become effective. If the Customer continues to use the Tool after
					the effective date, the Customer indicates his acceptance of the
					revised prices.
				</p>
				<p>
					<i>Indexing</i>
				</p>
				<p>
					Insofar as the prices of the Services are based on the then valid
					level of wage costs, costs of components/parts, social security
					contributions and government charges, insurance premiums, costs of
					materials, exchange rates and/or other costs, COACHALL is entitled to
					increase its prices accordingly in the event of an increase in one or
					more of these price factors and in accordance with the legally
					permitted standards (for License Fees: even during the current Term).
					An indexation cannot be regarded as a substantial amendment to the
					Agreement.
				</p>
				<Title order={2}>4. Terms of Use - the Tool </Title>
				<Title order={3}>4.1 Software License (right of use) </Title>
				<p>
					COACHALL grants the Customer a non-exclusive, non-transferable,
					non-assignable, worldwide limited right to use the Tool and all
					(future) updates thereof solely for its internal business operations.
				</p>
				<p>
					In any event, the Client represents and warrants (i) that all data
					(including registration data) provided will be complete, true and
					accurate and (ii) that it will ensure their accuracy.
				</p>
				<p>
					Any other right of use or exploitation not expressly granted to the
					Customer is excluded without the written consent of COACHALL.
				</p>
				<p>
					As long as this Agreement is in force, the Customer may use the Tool
					within the limits of the rights granted at the time of entering into
					the Agreement. The scope of the rights granted may be limited or
					extended during the term of the Agreement.
				</p>
				<p>
					Within the limits of the Software license granted by COACHALL,
					Customer may grant the agreed number of Users a right of access and
					use. Under Customer's responsibility, these Users are granted access
					to the Tool and may use it within Customer's internal business
					environment.
				</p>
				<p>
					Consequently, the obligations under these Terms of Service apply to
					Users who access the Tool through the Customer. The Customer
					undertakes to clearly inform all its Users of their obligations and
					the conditions of use of the Tool.
				</p>
				<p>
					The Client shall not allow any person other than the agreed Users to
					access or use the Tool. With respect to the Tool, the Client shall be
					responsible for the acts, conduct, use and all other acts of its
					employees, officers, directors and any third parties to whom it grants
					access, whether intentionally or not, directly or indirectly.
				</p>
				<p>
					In the event of non-compliance with the provisions of the Agreement,
					COACHALL may take measures against the Customer and its Users.
				</p>
				<Title order={3}>4.2 Registration/Manager accounts </Title>
				<p>
					The Customer will be able to access the Tool and use the Software
					License through the Administrator account.
				</p>
				<p>
					Each Administrator account belongs to one person only and may not be
					shared with any other person. The Administrator is advised to create a
					unique password and to change it regularly.
				</p>
				<p>
					The Customer shall be solely responsible for the protection and
					security of the Administrator account. In this context, the
					Administrator shall ensure the confidentiality of his account,
					including the confidentiality of the login data, such as the password.
					Only in cases of problems with the tool can they disclose their login
					details to COACHALL (e.g. when asking COACHALL for help via its
					contact page - see article 13).
				</p>
				<p>
					Any damage resulting from the Customer's careless handling of these
					login details is exclusively for the account of the Customer, who
					cannot under any circumstances invoke the liability of COACHALL.
				</p>
				<p>
					The Customer must report any (suspected) breach of confidentiality of
					its administrator account to COACHALL immediately so that it can take
					appropriate measures.
				</p>
				<Title order={3}>4.2 Registration/Manager Accounts </Title>
				<p>
					The Customer shall be able to access the Tool and use the Software
					License through the Administrator account.
				</p>
				<p>
					Each Administrator account belongs to one person only and may not be
					shared with any other person. The Administrator is advised to create a
					unique password and to change it regularly.
				</p>
				<p>
					The Customer shall be solely responsible for the protection and
					security of the Administrator account. In this context, the
					Administrator shall ensure the confidentiality of his account,
					including the confidentiality of the login data, such as the password.
					Only in cases of problems with the tool can they disclose their login
					details to COACHALL (e.g. when asking COACHALL for help via its
					contact page - see article 12).
				</p>
				<p>
					Any damage resulting from the Customer's careless handling of these
					login details is exclusively for the account of the Customer, who
					cannot under any circumstances invoke the liability of COACHALL.
				</p>
				<p>
					The Customer must report any (suspected) breach of confidentiality of
					its administrator account to COACHALL immediately so that it can take
					appropriate measures.
				</p>
				<Title order={3}>4.3 Restrictions </Title>
				<p>
					The rights granted under the Agreement are subject to restrictions.
					The Client agrees to use the Tool only for his internal business
					purposes and in compliance with all applicable laws, rules and
					regulations issued by the authorities.
				</p>
				<p>
					The Customer undertakes not to use the Tool for any activities which
					are or may be directly or indirectly unlawful, harmful, threatening,
					abusive, harassing, malicious or defamatory and not to perform any
					acts which infringe the (intellectual property) rights of COACHALL,
					its Affiliates or any third party.
				</p>
				<p>
					The Customer agrees that compliance with this article is an essential
					point of the Agreement.
				</p>
				<p>
					The Client agrees not to misuse the Tool and to abide by the
					restrictions imposed. Without this list being exhaustive, the Client
					undertakes not to commit the acts listed below:
				</p>
				<ul>
					<li>
						(re)sell, assign, distribute, (sub)license, rent, lease, loan,
						distribute or otherwise transfer the rights to the Tool granted
						under this Agreement without the prior written consent of COACHALL,
						or offer Services or its derivatives in a service bureau or
						outsourcing offering to a third party;
					</li>
					<li>
						Copy, modify, alter, translate, or create derivative works from the
						Tool (except for the copies, modifications, or derivative works
						solely of the reports or statements created for the Customer's
						internal business purposes);
					</li>
					<li>reverse engineer, disassemble, or decompile the Tool;</li>
					<li>
						Use the Tool for any unlawful purpose or for the transmission of
						data which is illegal, defamatory, invasive of another's privacy or
						which offends, threatens or harms the intellectual property of
						another
					</li>
					<li>Use the Tool to conduct or promote any illegal activity;</li>
					<li>
						Use the Tool to distribute "junk mail," "spam," "chain mail,"
						"phishing," or other unsolicited mass emails;
					</li>
					<li>Use the Tool to stalk, harass, or harm anyone;</li>
					<li>
						Disrupt the proper working of the Tool and/or the Website. This also
						includes that Customers shall not transmit, process or store any
						material that contains software viruses, worms, Trojan horses or any
						other harmful or malicious computer code, files, scripts, agents or
						programs that may infringe on the Services and interests of
						COACHALL, its Affiliates and Customers. Further, Customers may not
						upload content that could damage or disrupt the Tool's
						infrastructure and proper functioning;
					</li>
					<li>
						Post content to the Tool which may be considered inappropriate or
						unlawful due to its illegal, unlawful, harmful, abusive, misleading,
						threatening, obscene, pornographic, offensive or racist nature
						and/or more generally because it is contrary to public morality or
						order;
					</li>
					<li>
						Circumventing the business model of COACHALL or its Affiliates;
					</li>
					<li>
						Use the Tool, in whole or in part, in a manner that could create an
						incorrect or misleading impression, attribution or statement about
						COACHALL, its Affiliates or any third party;
					</li>
					<li>
						Solicit the login credentials of any other User and/or log into the
						User account of any other User;
					</li>
					<li>
						Use the Tool in a fraudulent manner (for example, using a false User
						Account and/or providing false User Information is considered
						fraudulent use);
					</li>
					<li>
						Unauthorized use of the Tool and impersonation of another person
						(legal or natural);
					</li>
					<li>
						Use the Tool in a way that is significantly more burdensome than
						average use and which may damage the network or overload the system.
					</li>
				</ul>
				<p>
					Customer shall (i) immediately penalize or sanction any inappropriate
					or unauthorized use and (ii) immediately notify COACHALL in writing.
				</p>
				<Title order={3}>4.4 Corrective measures </Title>
				<p>
					COACHALL may take all necessary and reasonable measures if the
					Customer or its User acts in breach of the Contract, applicable law,
					the rights of third parties or the generally accepted rules of conduct
					on the Internet. COACHALL grants itself a wide discretionary power to
					punish a breach proportionately. COACHALL may temporarily suspend or
					permanently deny access to the Tool. COACHALL may charge the Client
					for any costs it incurs as a result of the Client's non-compliance
					with the Conditions of Use.
				</p>
				<p>
					If the situation so warrants, COACHALL may take the aforementioned
					measures without prior warning. The Customer is not entitled to ask
					for a refund or compensation as a result of the measures taken.
				</p>
				<p>
					If the Customer believes that any measure taken is disproportionate or
					unfounded, it must write to COACHALL within fifteen (15) calendar days
					of taking the measure. COACHALL will consider the Customer's
					arguments, but is not obliged to reverse the measures taken.
				</p>
				<Title order={3}>4.5 System requirements </Title>
				<p>
					The use of and access to the Tool by the Customer requires an internet
					connection and the use of an up-to-date web browser (we support all
					browsers but recommend the latest version of Google Chrome). With an
					outdated web browser, the Customer may not be able to use all the
					functionalities of the Tool or they may not work optimally.
				</p>
				<Title order={2}>5. Invoicing and payment </Title>
				<Title order={3}>5.1 Invoicing </Title>
				<p>
					The Customer expressly agrees that COACHALL will issue electronic
					invoices, unless the Parties agree otherwise in writing. COACHALL will
					send all invoices under this Agreement to the electronic address the
					Customer provided when ordering the Services. It is Customer's
					responsibility to keep its billing information current. The Customer
					is not released from its payment obligation and the consequences of
					late or non-payment if COACHALL draws up and sends an invoice with the
					Customer's outdated billing information.
				</p>
				<Title order={3}>5.2 Payment </Title>
				<p>
					Unless otherwise expressly agreed in this respect, all COACHALL
					invoices are sent automatically on a monthly basis. To process its
					payments, COACHALL uses the services of external professional and
					specialised partners who operate a payment platform. Online payments
					are made using secure protocols. All online payments are subject to
					the general terms and conditions of the external payment platform
					operator, who is also responsible for the correct processing of all
					online payments.
				</p>
				<p>
					The customer's financial data which it enters as part of an online
					payment is exchanged exclusively between the external partner and the
					relevant financial institutions. COACHALL has no access to the
					Customer's confidential financial data.
				</p>
				<p>
					The Customer may only lawfully dispute invoices by sending an e-mail
					to (info@coachall.be) within seven (7) calendar days of the invoice
					date, stating the invoice date, invoice number and detailed reasons.
					Such a dispute does not discharge the Customer from his obligation to
					pay.
				</p>
				<p>
					The unconditional payment by the Customer of the invoice amount is
					regarded as express acceptance of the invoice. COACHALL will always
					accept partial payments from the Customer subject to change and
					without prejudice and allocate them first to collection costs, then to
					damage suffered, then to interest due and only then to the principal
					sum, with priority being given to allocating the paid amount to the
					oldest outstanding principal sum.
				</p>
				<Title order={3}>
					5.3 Consequences of non-payment or late payment{" "}
				</Title>
				<p>
					In the event of non-payment of an invoice on its due date, all
					outstanding invoices, even those not yet due, shall become immediately
					payable. Invoices not paid or paid late shall by operation of law and
					without notice of default attract interest equal to 8%, as well as
					(also by operation of law and without notice of default) a fixed
					compensation of 2% on the invoice amount (with a minimum of EUR 100)
					as a penalty, on top of the collection and administration costs.{" "}
				</p>
				<p>
					If the Customer fails to pay one or more invoices, COACHALL is
					entitled to temporarily suspend access to the Services until it has
					received actual and full payment from the Customer. However, the
					temporary suspension of access to the Services will not affect the
					continuation of the Term. Furthermore, COACHALL may unilaterally
					terminate the contract if the Customer fails to pay or repeatedly
					fails to pay on time.
				</p>
				<p>
					In the event of non-payment or late payment, all COACHALL's other
					invoices to the Customer will become immediately due and payable, even
					if they are not yet due, and all permitted payment terms will cease to
					apply. The same applies in the event of imminent bankruptcy, judicial
					or amicable dissolution, cessation of payment or any other fact
					indicating the Customer's insolvency.
				</p>
				<Title order={2}>6. Intellectual property </Title>
				<Title order={3}>6.1 Intellectual property rights of COACHALL </Title>
				<p>
					The Customer expressly acknowledges that COACHALL and its Affiliated
					Companies are and remain the sole owners of the Tool and/or other
					intellectual property rights relating to it. All such rights and
					goodwill are and will remain vested in COACHALL and its Affiliates.
				</p>
				<p>
					Accordingly, the license granted to the Customer under this Agreement
					(see Section 4.2) includes only the right to use - for a fee - the
					Tool. COACHALL does not grant any implied licenses under this
					Agreement.
				</p>
				<p>Under no circumstances is such a license granted: </p>
				<ul>
					<li>
						A transfer of ownership of the Tool from COACHALL to Customer;
					</li>
					<li>
						the Customer any right to the Tool or any trade names and/or
						trademarks of COACHALL or its Affiliates; or
					</li>
					<li>
						the Customer's right to ask COACHALL to deliver to it a copy of any
						software or other products used by COACHALL to provide the Services.
					</li>
				</ul>
				<p>
					Consequently, the Customer may not use any trademark, trade name or
					brand name of COACHALL or its Related Companies (such as, among other
					things, its use in meta tags, keywords or hidden text) without the
					express written consent of COACHALL and/or its Related Companies.
				</p>
				<p>
					Without prejudice to the Customer's or any third party's right to
					contest the validity of any intellectual property of COACHALL, the
					Customer will not perform any act, or allow any third party to perform
					any act, which could invalidate or conflict with COACHALL's
					intellectual property rights. This includes patents, copyrights,
					registered designs, trademarks or other industrial or intellectual
					property rights. Furthermore, the Customer shall not omit anything,
					nor shall it allow a third party to omit anything, that would have the
					same effect.
				</p>
				<p>
					The Customer undertakes to inform COACHALL of any actual, threatened
					or suspected infringements of any intellectual property rights of
					COACHALL of which it becomes aware and of any claim by a third party
					as a result of using the Services.
				</p>
				<Title order={3}>6.2 Documentation </Title>
				<p>
					In accordance with these Terms of Service, COACHALL hereby grants
					Customer a non-exclusive, non-transferable License for the Term of the
					concluded agreement (see Clause 8) to reproduce copies of the
					Documentation, albeit solely for the purpose of Customer's use within
					the scope of its License (see Clause 4.2).
				</p>
				<p>The Customer acknowledges that: </p>
				<ul>
					<li>
						no right is granted to publish, modify, adapt, translate or create
						derivative works from the Documentation;
					</li>
					<li>
						the Documentation is part of COACHALL's intellectual property and
						hereby agrees to accurately reproduce all proprietary notices,
						including any copyright, trademark and confidentiality notices that
						copies of the Documentation may contain.
					</li>
				</ul>
				<Title order={3}>6.3 References </Title>
				<p>
					Without granting any right, claim or interest, Customer agrees that
					COACHALL may make free and accurate informative references to
					Customer's trade names, trademarks or service marks (collectively the
					"Marks") in the provision of its Services, for example on the Website.
				</p>
				<p>
					COACHALL will cease all use of any of Customer's Trademarks for the
					performance of the Services within a reasonable period of time upon
					(i) the termination of the Agreement or (ii) the receipt of notice
					from Customer to cease such use.
				</p>
				<Title order={2}>7. Duration </Title>
				<p>
					Each Software License has a renewable term of one year. A new term of
					the same duration as the previous one year term (initial or extended)
					will automatically commence if Customer does not terminate the
					contract at least fifteen (15) calendar days before the renewal date,
					which will entitle COACHALL to invoice Customer for the extended term.
				</p>
				<p>
					In the event of a renewal, the applicable price will always be based
					on the status of the Customer Account immediately prior to the Renewal
					Date (regardless of any price increases, see Clause 3.3).
				</p>
				<Title order={2}>8. Termination </Title>
				<Title order={3}>8.1 Termination by Customer </Title>
				<p>
					The Customer may terminate the Software License by using the contact
					form on COACHALL's website.
				</p>
				<p>
					(i) At any time and for any reason, provided that notice is given at
					least fifteen (15) calendar days prior to the Renewal Date.
				</p>
				<p>
					Any termination of the Software License by Customer less than fifteen
					(15) calendar days prior to the expiry of the Term will be of no
					avail, as the Agreement will be automatically extended in such case
					(see Article 8). Consequently, the Customer will be obliged to pay the
					invoice for the extended Term, even if he does not intend to continue
					using the Services;
				</p>
				<p>
					(ii) In the event Customer cannot agree to a substantial amendment to
					the Agreement and such termination occurs within 30 calendar days of
					being notified by COACHALL.
				</p>
				<p>Substantial amendments to the Agreement include: </p>
				<ul>
					<li>
						a substantial amendment of these Terms of Service by COACHALL;
					</li>
					<li>
						a price increase that has a substantial impact on the Customer (see
						clause 3.3);
					</li>
					<li>
						a change to the range of Services which causes a substantial
						disadvantage to the Customer (see Article 15).
					</li>
				</ul>
				<p>
					Under no circumstances does a termination entitle the Customer to
					claim any form of damages or compensation from COACHALL.
				</p>
				<Title order={3}>8.2 Termination by COACHALL </Title>
				<p>
					Without prejudice to any right or remedy which COACHALL may have
					against the Customer, COACHALL is free to terminate the contract at
					any time and without judicial intervention in the event of exceptional
					circumstances which make it impossible to continue any professional
					collaboration between COACHALL and the Customer.
				</p>
				<p>
					The Customer agrees that the following circumstances are to be
					considered as exceptional circumstances:
				</p>
				<p>
					(i) If COACHALL determines or has reasonable grounds to suspect that:
				</p>
				<ul>
					<li>
						Customer is in material breach of any provision of these Terms of
						Service (in particular if Customer is in breach of the restrictions
						in Clause 4.4).
					</li>
					<li>
						To the extent it still deems useful, COACHALL will first inform
						Customer of the breach and request Customer to (i) rectify the
						situation and (ii) refrain from committing the breach and, if
						possible, (iii) prevent any such breach or breaches in the future;
					</li>
					<li>
						the Customer uses the Services for unauthorised, illegal and/or
						inappropriate purposes;
					</li>
					<li>
						the Agreement with the Customer is based on incorrect or erroneous
						information provided by the Customer; or
					</li>
					<li>
						the Customer ordered the Services for reasons that cannot be
						considered as objectively reasonable and acceptable.
					</li>
				</ul>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
				</p>
				<p>
					(ii) If the Customer ceases payments, files for bankruptcy, is
					declared bankrupt, goes into liquidation or similar proceedings or is
					dissolved.
				</p>
				<p>
					(iii) If the Customer commits any act of dishonesty, faithlessness or
					fraud against COACHALL or its Affiliated Companies.
				</p>
				<p>
					In the event of such a termination by COACHALL communicated by e-mail,
					the contract is automatically terminated without notice or
					compensation and without prejudice to COACHALL's right to claim
					additional compensation from the Customer. COACHALL is entitled to
					refuse any request from the Customer to conclude a (new) Agreement
					relating to the use of the Services.
				</p>
				<Title order={3}>8.3 Consequences of termination </Title>
				<p>Upon termination of the Software License: </p>
				<ul>
					<li>By the Customer:</li>
					<p>
						COACHALL undertakes to deactivate the Customer account as soon as
						reasonably possible after the Software License ends. COACHALL will
						notify Customer of the deactivation of the Customer account in
						advance to the best of its ability;{" "}
					</p>
					<li>by COACHALL:</li>
					<p>
						COACHALL (i) shall at its sole discretion determine a time period in
						which it will deactivate the Customer Account.
					</p>
				</ul>
				<p>
					Upon termination of the Agreement, each Party must return all
					confidential information and material belonging to the other Party
					(see Clause 11).
				</p>
				<p>
					If the Customer terminates the Agreement for any reason other than
					serious misconduct by COACHALL, it is obliged to pay the amount for
					the remainder of the current Term (and, if applicable, for the next
					Term) even if it will no longer use the Services. Any amounts already
					paid to COACHALL for the current and/or next Term will be retained by
					COACHALL and not refunded.
				</p>
				<p>
					The following clauses shall survive the expiry or termination of this
					Agreement for any reason: Clauses 7 (Intellectual Property), 9
					(Liability), 10 (Personal Data and Privacy) and 11 (Confidentiality).
				</p>
				<Title order={2}>9. Liability </Title>
				<p>
					COACHALL's liability shall be limited to the lower of (i) the invoice
					value of the last invoice relating to the Services or (ii) the amount
					of the payment of the insurance taken out by COACHALL. However,
					liability will in any event be limited to the statutory liability
					under Belgian law.
				</p>
				<p>
					The Customer accepts that it must always notify COACHALL as soon as
					possible of any damage it suffers as a result of using the Tool or
					Services. Any aggravation of the damage caused by not notifying
					COACHALL in this way without delay is not attributable to COACHALL.
				</p>
				<p>
					Unless something else is explicitly stated or preordained in this
					document and to the extent permitted by law, the Tool and/or Services
					as well as the Documentation and any other products or services
					supplied by COACHALL are provided on an 'as is' basis. COACHALL
					therefore disclaims all other promises, conditions, representations
					and warranties, whether express or implied, including but not limited
					to any implied warranties of fitness for a particular purpose,
					satisfactory quality, reasonable skill and care, system integration
					and/or data accuracy.
				</p>
				<p>
					Further, COACHALL does not warrant that the Tool and/or the Services
					will meet all of Customer's requirements. Furthermore, the Customer is
					offered the opportunity to (i) make use of the Demo (see article 3)
					before becoming a paying Customer and (ii) request additional
					information from COACHALL in this respect at any time. Consequently,
					the Customer declares to be sufficiently informed about the content
					and scope of the Tool and the Services.
				</p>
				<p>
					Without prejudice to the generality of the previous section, COACHALL
					does not warrant: (i) that the performance of the Tool and Services
					will be uninterrupted or error free or that all errors and/or bugs
					will be resolved (within a reasonable timeframe); (ii) that the Tool
					and Services will be continuously available, virus free, timely and
					complete; or (iii) that the information provided by the Tool and
					Services will be complete, correct, accurate and non-misleading.
				</p>
				<p>
					The intended use of the Tool and the Services by the Customer, the
					Administrator and/or the Users is determined entirely at their
					responsibility and risk. COACHALL shall not be held liable in any way
					for any direct or indirect damage that may result from this intended
					use. Therefore, Customer, Administrator and/or User shall be solely
					responsible for any consequential damage to their computer (programs),
					wireless devices and/or other equipment caused by the Tool and
					Services.
				</p>
				<p>COACHALL shall also not be liable for: </p>
				<ul>
					<li>
						indirect and/or consequential damages (including, among others, loss
						of revenue, loss of goodwill and damage to Client's property caused
						by the Tool and the Services). This limitation of liability applies
						even if COACHALL was specifically informed by Customer of the
						possible loss;
					</li>
					<li>
						defects caused directly or indirectly by any act of the Customer or
						any third party, whether caused by error or negligence;
					</li>
					<li>
						damage caused by the use of the Tool and the Services for any
						purpose other than the purpose for which it was developed or
						intended by COACHALL;
					</li>
					<li>
						additional damages caused by the continued use by the Customer, the
						Administrator and/or the Users after a defect has been detected;
					</li>
					<li>
						the loss or incorrect use of the Customer data, unless this is
						exclusively the fault of COACHALL;
					</li>
					<li>
						losses caused by failure to comply with any advice and/or guidelines
						given by COACHALL, which it always provides on a discretionary
						basis;
					</li>
					<li>damage caused by force majeure or hardship (see Article 16).</li>
				</ul>
				<p>
					The Client further accepts that COACHALL does not in any way guarantee
					that the Tool and the Services comply with the regulations or
					requirements which apply at any legal level, with the exception of the
					regulations or requirements which apply in Belgium at the time the
					contract is concluded. COACHALL cannot therefore be held liable for
					any subsequent change of any kind in such legislation and/or
					regulations.
				</p>
				<p>
					The Customer is not expected to provide any (confidential) information
					(e.g. an Excel sheet with data, including Customer data) or any login
					details to any employee of COACHALL in any way or for any reason.
					Should the Customer, despite the above, still transmit any such
					information to COACHALL, the Customer acknowledges that he does so
					entirely at his own risk. In this case, COACHALL cannot guarantee the
					same security and confidentiality of the information provided as it
					does for Customer data.
				</p>
				<p>
					The Customer will indemnify and/or hold COACHALL and/or all its
					officers, directors, partners, employees and Affiliated Companies
					harmless from and against any claims which may arise from the
					existence, performance, non-performance and/or termination of these
					Terms of Service and which are caused by its own negligence, fault or
					carelessness or by its Administrator and/or any of its Users.
				</p>
				<Title order={2}>10. Personal data and privacy </Title>
				<Title order={3}>10.1 COACHALL as data controller </Title>
				<p>
					COACHALL processes personal data of the (potential) Customer, its
					Users and/or other employees of the Customer. As COACHALL processes
					personal data for a range of its own purposes (mainly to provide the
					Services, but also, for example, to send newsletters and other
					marketing communications), COACHALL acts as 'data processor' within
					the meaning of the Privacy Act.
				</p>
				<p>
					COACHALL's Privacy Statement contains all relevant information on how
					COACHALL handles personal data as a data processor (including: the
					purposes of the data processing, the nature of the personal data to be
					processed, the period for which it is kept, the recipients of the
					data, etc.). COACHALL's Privacy Statement must be read together with
					COACHALL's Cookie Policy.
				</p>
				<p>
					By entering into a contract with COACHALL - the Customer acknowledges
					that they have read the Privacy Statement and understand its content.
				</p>
				<Title order={3}>10.2 COACHALL as data processor </Title>
				<p>
					As regards the processing of the Customer's Personal Data, the
					Customer acknowledges that it will act as 'data controller' and
					COACHALL as 'data processor' within the meaning of the Privacy Act.
					Any arrangements the Parties agree in this respect will be governed
					exclusively by the Data Processing Agreement, which is attached as an
					Annex to these Terms of Service and made available in the
					Administrator Account.
				</p>
				<p>
					By entering into an Agreement with COACHALL - the Customer indicates
					that it has read and accepts the Data Processing Agreement.
				</p>
				<Title order={2}>11. Confidentiality</Title>
				<Title order={3}>11.1 General</Title>
				<p>
					All information that the Parties exchange with each other in writing
					or orally before concluding the Agreement and during the term of the
					Agreement is considered confidential and must be treated with the
					strictest secrecy by each Party.
				</p>
				<p>
					This includes, but is not limited to: all information of a financial,
					commercial, legal, fiscal, social, technical and organisational
					nature, trade and business secrets, business partner data, customer
					and supplier data, employee data, personal data, programs, source
					codes, computer programs, computer code, modules, scripts, algorithms,
					features and methods of operation, inventions (whether patentable or
					not), processes, diagrams, test procedures, software design and
					architecture, design and function specifications. In the case of the
					Customer, this also includes Customer Data.
				</p>
				<p>Specifically, the recipient shall: </p>
				<ul>
					<li>
						use the confidential information exclusively for its own account and
						with the strictest confidentiality;
					</li>
					<li>
						not use, reproduce or allocate the confidential information in any
						way or for any purpose other than the (possible) cooperation between
						the parties;
					</li>
					<li>
						not reverse engineer, disassemble or decompile any of the
						confidential information (or have it disassembled);
					</li>
					<li>
						not to derive any commercial advantage from the confidential
						information;
					</li>
					<li>
						not reveal, disclose or make available to third parties the
						confidential information of which he has knowledge without the
						express written consent of the disclosing party;
					</li>
					<li>
						only disclose such confidential information to employees who need to
						know it in the context of the (possible) cooperation between the
						Parties. In addition, the Receiver represents and warrants that such
						employees have agreed in advance, as a condition of their
						employment, to be bound by terms and conditions substantially
						similar to those applicable to the Receiver under these Terms of
						Service.
					</li>
				</ul>
				<p>
					The disclosing party shall at all times remain the sole owner of its
					confidential information. Except as expressly provided in Articles
					12.2 and 12.3, nothing in these Terms of Service confers on the
					recipient any right or interest in the Confidential Information.
				</p>
				<p>
					This confidentiality obligation shall apply for the duration of the
					Agreement between the Parties and for a period of five (5) years after
					termination of the Agreement for any reason.
				</p>
				<Title order={3}>11.2 Exceptions applicable to both Parties </Title>
				<p>
					The obligations set out in Article 12.1 do not apply to the following
					information
				</p>
				<ul>
					<li>
						information that is publicly available, has been publicly
						disseminated and/or is known to the general public at the time of
						its disclosure;
					</li>
					<li>
						information that the recipient lawfully received from a third party
						other than the disclosing party, where that third party was in turn
						not bound by any confidentiality agreement with the disclosing
						party;
					</li>
					<li>
						information the disclosure/announcement of which is required by law
						or mandated by a court or governmental decision (of any kind).
						Whenever possible, the recipient shall discuss the scope and manner
						of such disclosure/announcement with the disclosing party before
						disclosing/announcing anything.
					</li>
				</ul>
				<Title order={3}>11.3 Exceptions applicable to COACHALL </Title>
				<p>
					Notwithstanding the confidentiality obligation in clause 12.1,
					Customer expressly grants COACHALL and its Affiliates a worldwide,
					perpetual, irrevocable, royalty-free license to:
				</p>
				<ul>
					<li>
						use and/or trade any idea, improvement request and any input,
						suggestion, recommendation, correction or other feedback from the
						Customer that may improve and/or enhance the Tool and the Services;
					</li>
					<li>
						collect, use and disclose quantitative data derived from the use of
						the Tool and Services by the Customer (e.g. average number of
						invoices issued, contacts added, users ...) for business purposes,
						including sectoral analysis, benchmarking, analysis and marketing.
						all data disclosed shall be available only in aggregated and
						unidentified form and shall not identify the Customer, its Users or
						any third party;
					</li>
					<li>
						use all anonymous and unidentified Customer Data (i.e. data that
						does not identify a person) for commercial purposes.
					</li>
				</ul>
				<Title order={2}>12. Support </Title>
				<p>
					Should the Customer need help or have a question about the Services,
					he is advised to first take a look at the Support page of the Tool.
				</p>
				<p>
					If the information they find there does not help them, they can
					contact COACHALL free of charge via the contact page. COACHALL is
					available Monday to Friday from 9.00 to 17.00 (CET), except on
					official holidays and replacement days for official holidays which
					fall on a weekend.
				</p>
				<p>
					COACHALL will do its utmost to help the customer as soon as possible
					after receiving the support request.
				</p>
				<Title order={2}>13. Availability, Maintenance and Updates </Title>
				<p>
					Should there be any problems with the availability of the Services,
					COACHALL undertakes to do its utmost to solve the problem as quickly
					as reasonably possible, without, however, making any guarantees as to
					the response and solution times. In any case and where appropriate,
					COACHALL will be free to determine what, in this respect, may be
					considered an appropriate solution or compensation in respect of its
					Customers.
				</p>
				<p>
					COACHALL aims to maintain the quality of the Services by carrying out
					regular maintenance work and updates. COACHALL undertakes to keep the
					impact of such maintenance work and updates on the availability of the
					Services to a minimum, but does not rule out any downtime in this
					respect. In any case, COACHALL will do its utmost to inform the
					Customer of this in due time, unless this is impossible or not
					worthwhile (such as in urgent cases).
				</p>
				<p>
					Under no circumstances will COACHALL be obliged to compensate the
					Customer for the downtime.
				</p>
				<Title order={2}>14. Notices </Title>
				<p>
					All notices to be given by COACHALL to the Customer under this
					Agreement will be sufficient if COACHALL addresses its Notice to the
					last known contact details of the Customer (which it may have provided
					when enrolling). All Users are expected to keep their contact details
					up to date. COACHALL cannot be held liable if a Customer did not
					receive a particular Communication because the available contact
					details were no longer up to date.
				</p>
				<p>
					Any formal communication to COACHALL should be addressed to the
					contact addresses provided for this purpose in these Terms of Service.
				</p>
				<Title order={2}>15. Changes to the Services </Title>
				<p>
					COACHALL is entitled to change the range and composition of its
					Services (including but not limited to the range and composition of
					the Packages, the functionalities supported) at any time. Where
					appropriate, COACHALL will inform the Customer of this within a
					reasonable time prior to a change by means of a Communication.
				</p>
				<Title order={2}>16. Hardship </Title>
				<p>
					COACHALL cannot be held liable for not fulfilling any of its
					obligations under the Contract if this is due to force majeure or
					hardship.
				</p>
				<p>
					Usual situations of force majeure or hardship include the following:
					all circumstances which were not reasonably foreseeable and
					unavoidable at the time of entering into the Contract, and which
					prevent COACHALL from performing the Contract, or which make the
					performance of the Contract more difficult financially or otherwise
					than would normally be the case (including, inter alia: War, natural
					disasters, fire, seizure, epidemics and pandemics, delays in or
					bankruptcy of third parties engaged by COACHALL, staff shortages,
					strikes, organizational circumstances, threat or acts of terrorism,
					government interventions, power failures and breakdowns or
					interruptions of any communication equipment, software or hardware).
				</p>
				<p>
					The situations referred to entitle COACHALL to review and/or suspend
					the performance of the contract by simple written notification to the
					Customer, without COACHALL being liable to pay any compensation.
					COACHALL is entitled to terminate the contract if the situation of
					force majeure and/or hardship lasts longer than two (2) months.
				</p>
				<Title order={2}>17. Set-off (compensation) </Title>
				<p>
					In accordance with the provisions of the Belgian Financial Securities
					Act of 15 December 2004, the Parties agree that within the framework
					of their mutual relationship, from the start of the agreement between
					COACHALL and the Customer, all currently existing as well as future
					debts will always be automatically and ipso jure set off against each
					other and settled, regardless of their due date, their purpose or the
					currency in which they are expressed.
				</p>
				<p>
					If there are several creditors, the claim of the other party against
					the party facing several creditors will always be limited to what
					remains after setting off the amounts to be charged. Moreover, the
					permanent set-off will in any case have legal effect vis-à-vis the
					liquidator and the other creditors, who will therefore not be able to
					object nor oppose any aforementioned set-off or set-off that the
					Parties would carry out.
				</p>
				<Title order={2}>18. Applicable Law and Competent Court </Title>
				<p>
					All issues, questions and disputes concerning the validity,
					interpretation, enforcement, performance or termination of this
					Agreement shall be governed by and construed in accordance with the
					provisions of Belgian law.
				</p>
				<p>
					All disputes relating to the validity, interpretation, enforcement,
					performance or termination of this Agreement will be settled
					exclusively by the courts where COACHALL has its registered office.
				</p>
				<Title order={2}>19. Language </Title>
				<p>
					Unless expressly agreed otherwise, the Customer acknowledges that the
					language of these terms of service is also the working language in all
					commercial transactions with COACHALL.
				</p>
				<p>
					The original language of these terms of service is English.
					Translations or documents drafted in another language will always be
					regarded as an additional benefit for the Customer. In the event of a
					conflict between the different versions, the English version always
					prevails.
				</p>
				<Title order={2}>20. Miscellaneous Provisions </Title>
				<p>
					The nullity of one or more provisions or part of these Service
					Conditions shall not affect the validity and enforceability of the
					other clauses and/or the rest of the provision concerned. If
					appropriate, the Parties will negotiate to replace the void provision
					with an equivalent clause that is in keeping with the spirit of these
					Service Terms. If the Parties fail to reach agreement, the competent
					court may mitigate the invalid provision to the extent permitted by
					law.
				</p>
				<p>
					If COACHALL does not exercise any of its rights (repeatedly), this can
					only be interpreted as tolerating a particular situation and does not
					imply a waiver of its rights in any way.
				</p>
				<p>
					COACHALL shall be entitled to assign or transfer this Agreement in
					whole or in part to an Affiliate or any other company in the event of
					a sale, transfer, merger, consolidation or any other disposition of
					(substantially) all of its assets or business.
				</p>
			</Container>
		</>
	);
}
