import React from "react";
import {
	PasswordInput,
	Group,
	Box,
	Stack,
	Text,
	Title,
	Button,
	TextInput,
} from "@mantine/core";
import { IconDeviceFloppy, IconDatabase, IconCheck } from "@tabler/icons";
import { db, functions, auth } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { showNotification } from "@mantine/notifications";

export default function Settings() {
	const [solarAPI, setSolarAPI] = React.useState("");
	const [domain, setDomain] = React.useState("");
	const [TLconnected, setTLConnected] = React.useState(false);
	const { companyId } = useAuth();

	const companyRef = db.collection("companies");

	const connectTeamleader = (e) => {
		e.preventDefault();
		const url = `https://focus.teamleader.eu/oauth2/authorize?client_id=831193f2925857d103319513c9eaa1bf&state=${companyId}&response_type=code&redirect_uri=https:%2F%2Feurope-west1-solar-teamleader-api.cloudfunctions.net%2FconnectTeamleader`;
		window.location.href = url;
	};

	React.useEffect(() => {
		companyRef.doc(auth.currentUser.uid).onSnapshot((e) => {
			setSolarAPI(e.data().solarAPI);
			setTLConnected(e.data().TLconnected);
			setDomain(e.data().domain);
		});
	}, []);

	const saveSolarAPI = () => {
		companyRef
			.doc(auth.currentUser.uid)
			.update({
				solarAPI: solarAPI,
			})
			.then((e) => {
				showNotification({
					icon: <IconCheck />,
					color: "teal",
					title: "API key saved successfully!",
					//message: "API key saved successfully",
				});
			});
	};

	const saveSolarDomain = () => {
		companyRef
			.doc(auth.currentUser.uid)
			.update({
				domain: domain,
			})
			.then((e) => {
				showNotification({
					icon: <IconCheck />,
					color: "teal",
					title: "Domain saved successfully!",
					//message: "API key saved successfully",
				});
			});
	};

	return (
		<Box>
			<Stack align="flex-start" justify="flex-start" spacing="xl">
				<Title order={2}>Permissions</Title>
				<Group sx={{ alignItems: "flex-end" }}>
					<PasswordInput
						placeholder="API-key"
						label="2Solar API key"
						description="Contact your 2Solar account manager to get an API key"
						value={solarAPI}
						required
						onChange={(e) => setSolarAPI(e.target.value)}
						sx={{ width: "400px" }}
					/>
					<Button
						onClick={() => saveSolarAPI()}
						leftIcon={<IconDeviceFloppy size={18} />}
					>
						Save
					</Button>
				</Group>
				<Group sx={{ alignItems: "flex-end" }}>
					<TextInput
						placeholder="https://mycompany.2solar.nl/"
						label="2Solar domain"
						description="Your 2Solar domain (ex: https://mycompany.2solar.nl) with NO trailing slash (/) "
						value={domain}
						required
						onChange={(e) => setDomain(e.target.value)}
						sx={{ width: "400px" }}
					/>
					<Button
						onClick={() => saveSolarDomain()}
						leftIcon={<IconDeviceFloppy size={18} />}
					>
						Save
					</Button>
				</Group>
				<Group sx={{ alignItems: "flex-end" }}>
					<Box sx={{ alignItems: "flex-end" }}>
						<Text size="sm" sx={{ display: "flex", alignItems: "center" }}>
							Connect Teamleader{" "}
							{TLconnected && <IconCheck color="teal" size="16px" />}
						</Text>
						<Text size="xs" color="#868e96">
							Press the button to establish the connection with your Teamleader
							Focus account
						</Text>
					</Box>
					<Button
						onClick={connectTeamleader}
						leftIcon={<IconDatabase size={18} />}
					>
						{!TLconnected ? "Connect" : "Reconnect"}
					</Button>
				</Group>
			</Stack>
		</Box>
	);
}
