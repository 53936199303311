import React from "react";
import {
	ActionIcon,
	Group,
	Box,
	Stack,
	Text,
	Title,
	Badge,
	Switch,
	Tooltip,
	Skeleton,
	Button,
} from "@mantine/core";
import { IconRefresh } from "@tabler/icons";
import { db, functions } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

export default function Automations() {
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const { currentUser, companyId } = useAuth();

	React.useEffect(() => {
		db.collection("companies")
			.doc(currentUser.uid)
			.collection("automations")
			.onSnapshot((snapshot) => {
				let automations = [];
				snapshot.forEach((automation) =>
					automations.push({
						id: automation.id,
						...automation.data(),
					})
				);
				setData(automations);
				setLoading(false);
			});
	}, []);

	const setAutomationValue = (id, value) => {
		db.collection("companies")
			.doc(currentUser.uid)
			.collection("automations")
			.doc(id)
			.update({
				active: value,
			});
	};

	const forceUpdate = () => {
		const forceAutomationRun = functions.httpsCallable("forceAutomationRun");
		setLoading(true);
		forceAutomationRun({
			uid: currentUser.uid,
		})
			.then((e) => {
				if (e.data.status === 200) {
					setLoading(false);
				}
			})
			.catch((e) => setLoading(false));
	};

	const getCustomFields = () => {
		const get = functions.httpsCallable("getCustomFields");
		get({
			uid: currentUser.uid,
		});
	};

	return (
		<Box style={{ height: "100%", position: "relative" }}>
			<Stack justify="flex-start" spacing="xl">
				<Group position="apart">
					<Title order={2}>Automations</Title>
					<Tooltip label="Force Sync" position="bottom-end" withArrow>
						<ActionIcon
							color="primary"
							variant="filled"
							onClick={() => forceUpdate()}
						>
							<IconRefresh size={16} />
						</ActionIcon>
					</Tooltip>
				</Group>
				{data.map((automation) => {
					const date = new Date(automation.date);
					return (
						<Group key={automation.id} sx={{ alignItems: "flex-end" }}>
							<Text>{automation.description}</Text>
							<Switch
								checked={automation.active}
								onLabel="ON"
								size="md"
								disabled={loading}
								offLabel="OFF"
								onChange={(event) =>
									setAutomationValue(automation.id, event.currentTarget.checked)
								}
							/>
							{automation.active && loading ? (
								<Skeleton height="1.2rem" mt={6} width="320px" />
							) : (
								<>
									<Badge
										variant="outline"
										color={automation.status === 200 ? "teal" : "read"}
									>
										Status - {automation.status}: {automation.message}
									</Badge>
									<Text size="xs" color="gray">
										Last sync: {date.toLocaleString()}
									</Text>
								</>
							)}
						</Group>
					);
				})}
			</Stack>
		</Box>
	);
}
