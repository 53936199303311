import React from "react";
import {
	Container,
	Center,
	Card,
	TextInput,
	PasswordInput,
	Stack,
	Button,
	Image,
	Text,
	LoadingOverlay,
	Anchor,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import background from "../img/Background.webp";

export default function Login() {
	const [error, setError] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const { login } = useAuth();

	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
		},
	});

	const handleLogin = async (values) => {
		setLoading(true);
		await login(values.email, values.password)
			.then((e) => {
				if (e.includes("Error")) {
					setError(e);
					setLoading(false);
				}
			})
			.catch((error) => {
				setError(error);
			});
	};

	const date = new Date();

	return (
		<Container
			style={{
				height: "100vh",
				background: `#FF0000, url(${background})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				maxWidth: "100%",
				boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.95)",
			}}
		>
			<Center
				style={{
					height: "100vh",
					flexDirection: "column",
					justifyContent: "space-evenly",
				}}
			>
				<Container
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Image
						radius="md"
						src={logo}
						alt="Random unsplash image"
						width={500}
						sx={{ marginBottom: "4rem" }}
					/>

					<Card
						shadow="sm"
						p="lg"
						radius="md"
						withBorder
						sx={{ width: "350px" }}
					>
						<LoadingOverlay visible={loading} overlayBlur={2} />

						<form onSubmit={form.onSubmit((values) => handleLogin(values))}>
							<Stack spacing="lg">
								<TextInput
									placeholder="E-mail"
									required
									size="md"
									name="email"
									{...form.getInputProps("email")}
								/>
								<PasswordInput
									placeholder="Password"
									size="md"
									name="password"
									required
									{...form.getInputProps("password")}
								/>
								{error !== "" && (
									<Text color="red" size="sm">
										{error}
									</Text>
								)}
								<Button type="submit" size="md" disabled={loading}>
									Login
								</Button>
							</Stack>
						</form>
					</Card>
				</Container>
				<Text size="sm" color="gray">
					© {date.getFullYear()} -{" "}
					<Anchor
						href="https://www.coachall.be"
						target="_blank"
						rel="noopener noreferrer"
					>
						Coachall BV
					</Anchor>{" "}
					- All rights reserved -{" "}
					<Anchor component={Link} to="/help">
						Knowledgebase
					</Anchor>{" "}
					-{" "}
					<Anchor component={Link} to="/terms-of-service">
						Terms of service
					</Anchor>
				</Text>
			</Center>
		</Container>
	);
}
