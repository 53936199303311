import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const app = firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

if (window.location.hostname === "localhost") {
	console.log(
		"testing locally -- hitting local functions and firestore emulators"
	);
	app.functions("europe-west1").useEmulator("localhost", 5001);
}

export const auth = app.auth();
export const db = app.firestore();
export const functions = app.functions("europe-west1");
export var storage = app.storage();
export default app;
