import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import firebase from "firebase/compat/app";
const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isFirstLogin, setIsFirstLogin] = useState(false);
	const [companyId, setCompanyId] = useState("");

	const companyRef = db.collection("companies");
	async function login(email, password) {
		return auth
			.signInWithEmailAndPassword(email, password)
			.then((user) => {
				setCurrentUser(user.user);
			})
			.catch((error) => {
				let errorMessage;
				switch (error.code) {
					case "auth/user-not-found":
						errorMessage = "User not found";
						break;
					case "auth/invalid-password":
						errorMessage = "Invalid password";
						break;
					case "auth/too-many-requests":
						errorMessage = "Too many failed login attempts. Try again later";
						break;
					default:
						errorMessage = "Error logging in";
				}
				return `Error: ${errorMessage}`;
			});
	}

	//db queries

	function logout() {
		return auth.signOut();
	}

	async function resetPassword(email) {
		return auth
			.sendPasswordResetEmail(email)
			.then(function () {
				// Email sent.
				console.log("Email Sent");
			})
			.catch(function (error) {
				// An error happened.
			});
	}

	const newPassword = async (newPassword) => {
		await currentUser
			.updatePassword(newPassword)
			.then(() => {
				console.log("Password updated!");
				return "updated";
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const reauthenticate = (currentPassword) => {
		var user = auth.currentUser;
		var cred = firebase.auth.EmailAuthProvider.credential(
			user.email,
			currentPassword
		);
		return user.reauthenticateWithCredential(cred);
	};

	React.useLayoutEffect(() => {
		const unsubscribe = auth.onIdTokenChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
			if (user) {
				auth.currentUser
					.getIdTokenResult()
					.then(async (idTokenResult) => {
						if (idTokenResult) {
							const newUser = await companyRef
								.doc(idTokenResult.claims.companyId)
								.get()
								.then((e) => e.data().isFirstLogin);
							setIsFirstLogin(newUser);
							setIsAdmin(idTokenResult.claims.admin);
							setCompanyId(idTokenResult.claims.companyId);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const value = {
		currentUser,
		login,
		logout,
		resetPassword,
		loading,
		isAdmin,
		companyId,
		isFirstLogin,
		newPassword,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
