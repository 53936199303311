import React from "react";
import { Text, Navbar, Divider } from "@mantine/core";
import { ThemeIcon, UnstyledButton, Group } from "@mantine/core";
import {
	IconAdjustments,
	IconUsers,
	IconRefresh,
	IconZoomQuestion,
} from "@tabler/icons";
import { User } from "./User";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Nav() {
	const { isAdmin } = useAuth();
	let navigate = useNavigate();

	return (
		<Navbar width={{ base: 310 }} p="xs">
			<Navbar.Section grow mt="xs">
				<UnstyledButton
					sx={(theme) => ({
						display: "block",
						width: "100%",
						padding: theme.spacing.xs,
						borderRadius: theme.radius.sm,
						color:
							theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

						"&:hover": {
							backgroundColor:
								theme.colorScheme === "dark"
									? theme.colors.dark[6]
									: theme.colors.gray[0],
						},
					})}
					onClick={() => navigate("/")}
				>
					<Group>
						<ThemeIcon color="blue" variant="light">
							<IconAdjustments size={16} />
						</ThemeIcon>

						<Text>Settings</Text>
					</Group>
				</UnstyledButton>
				<UnstyledButton
					sx={(theme) => ({
						display: "block",
						width: "100%",
						padding: theme.spacing.xs,
						borderRadius: theme.radius.sm,
						color:
							theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

						"&:hover": {
							backgroundColor:
								theme.colorScheme === "dark"
									? theme.colors.dark[6]
									: theme.colors.gray[0],
						},
					})}
					onClick={() => navigate("/automations")}
				>
					<Group>
						<ThemeIcon color="red" variant="light">
							<IconRefresh size={16} />
						</ThemeIcon>

						<Text>Automations</Text>
					</Group>
				</UnstyledButton>
				{isAdmin && (
					<UnstyledButton
						sx={(theme) => ({
							display: "block",
							width: "100%",
							padding: theme.spacing.xs,
							borderRadius: theme.radius.sm,
							color:
								theme.colorScheme === "dark"
									? theme.colors.dark[0]
									: theme.black,

							"&:hover": {
								backgroundColor:
									theme.colorScheme === "dark"
										? theme.colors.dark[6]
										: theme.colors.gray[0],
							},
						})}
						onClick={() => navigate("/users")}
					>
						<Group>
							<ThemeIcon color="teal" variant="light">
								<IconUsers size={16} />
							</ThemeIcon>

							<Text>Users</Text>
						</Group>
					</UnstyledButton>
				)}
				<Divider />
				<UnstyledButton
					sx={(theme) => ({
						display: "block",
						width: "100%",
						padding: theme.spacing.xs,
						borderRadius: theme.radius.sm,
						color:
							theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

						"&:hover": {
							backgroundColor:
								theme.colorScheme === "dark"
									? theme.colors.dark[6]
									: theme.colors.gray[0],
						},
					})}
					onClick={() => navigate("/help")}
				>
					<Group>
						<ThemeIcon color="orange" variant="light">
							<IconZoomQuestion size={16} />
						</ThemeIcon>

						<Text>Knowledgebase</Text>
					</Group>
				</UnstyledButton>
			</Navbar.Section>
			<Navbar.Section>
				<User />
			</Navbar.Section>
		</Navbar>
	);
}
