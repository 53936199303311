import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { db, functions } from "../firebase";
import { IconCheck, IconX, IconPlus, IconUserX } from "@tabler/icons";
import {
	Button,
	Drawer,
	Stack,
	TextInput,
	LoadingOverlay,
	PasswordInput,
	Text,
	Popover,
	Title,
	UnstyledButton,
	ScrollArea,
	Checkbox,
	Group,
	Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

const columns = [
	{ field: "name", headerName: "Company", width: 200 },
	{ field: "email", headerName: "Email", width: 300 },
	{
		field: "verified",
		headerName: "Verified",
		align: "center",
		headerAlign: "center",
		width: 100,
		renderCell: (params) => {
			if (!params.value) {
				return <IconCheck color="teal" />;
			} else {
				return <IconX color="red" />;
			}
		},
	},
	{
		field: "solarAPI",
		align: "center",
		headerAlign: "center",
		headerName: "2Solar API",
		width: 100,
		renderCell: (params) => {
			if (params.value) {
				return <IconCheck color="teal" />;
			} else {
				return <IconX color="red" />;
			}
		},
	},
	{
		field: "teamleader",
		headerName: "Teamleader API",
		align: "center",
		headerAlign: "center",
		width: 150,
		renderCell: (params) => {
			if (params.value) {
				return <IconCheck color="teal" />;
			} else {
				return <IconX color="red" />;
			}
		},
	},
];

export default function Users() {
	const [users, setUsers] = React.useState([]);
	const [newUserModal, setNewUserModal] = React.useState(false);
	const [editUserModal, setEditUserModal] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState({});
	const [confirmDelete, setConfirmDelete] = React.useState(false);
	const [automations, setAutomations] = React.useState([]);
	const [activeAutomations, setActiveAutomations] = React.useState([]);

	const [error, setError] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const handleSignup = async (values) => {
		setLoading(true);
		const create = functions.httpsCallable("createUser");
		create({
			email: values.email,
			password: values.password,
			name: values.name,
		}).then((e) => {
			form.reset();
			setNewUserModal(false);
			showNotification({
				icon: <IconCheck />,
				color: "teal",
				title: "User created!",
				//message: "API key saved successfully",
			});
			setLoading(false);
		});
	};

	const handleDelete = async (id) => {
		setLoading(true);
		const deleteUser = functions.httpsCallable("deleteUser");
		deleteUser({
			uid: id,
		}).then(() => {
			setEditUserModal(false);
			setConfirmDelete(false);
			showNotification({
				icon: <IconCheck />,
				color: "teal",
				title: "User deleted!",
				//message: "API key saved successfully",
			});
			setLoading(false);
		});
	};

	const form = useForm({
		initialValues: {
			name: "",
			email: "",
			password: "",
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
		},
	});

	React.useEffect(() => {
		db.collection("companies").onSnapshot((snapshot) => {
			let array = [];
			snapshot.forEach(async (company) => {
				array.push({
					id: company.id,
					userid: company.data().uid,
					name: company.data().name,
					email: company.data().email,
					verified: company.data().isFirstLogin,
					solarAPI: Boolean(company.data().solarAPI !== ""),
					teamleader: Boolean(company.data().refreshToken !== ""),
				});
			});
			setUsers(array);
		});
		db.collection("scripts").onSnapshot((snapshot) => {
			let options = [];
			snapshot.forEach((automation) => {
				options.push({
					value: automation.id,
					description: automation.data().description,
					title: automation.data().title,
				});
			});
			setAutomations(options);
		});
	}, []);

	React.useEffect(() => {
		let active = [];

		db.collection("companies")
			.doc(selectedUser.id)
			.collection("automations")
			.onSnapshot((e) =>
				e.forEach((doc) => {
					active.push({
						value: doc.id,
					});
				})
			);
		setActiveAutomations(active);
	}, []);

	const changeAutomation = (id, description, title, value) => {
		const automationRef = db
			.collection("companies")
			.doc(selectedUser.id)
			.collection("automations");
		if (!value) {
			automationRef
				.doc(id)
				.delete()
				.then(async () => {
					let active = [];

					await db
						.collection("companies")
						.doc(selectedUser.id)
						.collection("automations")
						.get()
						.then((e) =>
							e.forEach((doc) => {
								active.push({
									value: doc.id,
								});
							})
						);
					setActiveAutomations(active);
				});
		} else {
			automationRef
				.doc(id)
				.set({
					active: false,
					description: description,
					title: title,
				})
				.then(async () => {
					let active = [];

					await db
						.collection("companies")
						.doc(selectedUser.id)
						.collection("automations")
						.get()
						.then((e) =>
							e.forEach((doc) => {
								active.push({
									value: doc.id,
								});
							})
						);
					setActiveAutomations(active);
				});
		}
	};

	return (
		<>
			<Drawer
				opened={newUserModal}
				onClose={() => setNewUserModal(false)}
				position="right"
				title="New user"
				padding="xl"
				size="xl"
			>
				<LoadingOverlay visible={loading} overlayBlur={2} />

				<form onSubmit={form.onSubmit((values) => handleSignup(values))}>
					<Stack spacing="lg">
						<TextInput
							placeholder="Company"
							required
							size="md"
							name="name"
							{...form.getInputProps("name")}
						/>
						<TextInput
							placeholder="Email"
							required
							size="md"
							name="email"
							{...form.getInputProps("email")}
						/>
						<PasswordInput
							placeholder="Password"
							size="md"
							name="password"
							required
							{...form.getInputProps("password")}
						/>
						{error !== "" && (
							<Text color="red" size="sm">
								{error}
							</Text>
						)}
						<Button type="submit" size="md" disabled={loading}>
							Signup
						</Button>
					</Stack>
				</form>
			</Drawer>
			<Drawer
				opened={editUserModal}
				onClose={() => {
					setEditUserModal(false);
					setConfirmDelete(false);
					setSelectedUser({});
				}}
				position="right"
				title="Edit user"
				padding="xl"
				size="xl"
			>
				<LoadingOverlay visible={loading} overlayBlur={2} />

				<Stack spacing="lg">
					<Divider />
					<Text>
						<b>Name:</b> {selectedUser.name}
					</Text>
					<Text>
						<b>Email:</b> {selectedUser.email}
					</Text>
					<Divider />
					<Title order={3}>Automations</Title>
					<ScrollArea style={{ height: 250 }}>
						<Stack>
							{automations.map((e) => {
								const value = activeAutomations.some((i) =>
									i.value.includes(e.value)
								);
								return (
									<Checkbox
										key={e.value}
										checked={value}
										label={e.value}
										onChange={(value) => {
											changeAutomation(
												e.value,
												e.description,
												e.title,
												value.currentTarget.checked
											);
										}}
									/>
								);
							})}
						</Stack>
					</ScrollArea>
					<Divider />
					<Popover
						opened={confirmDelete}
						width={300}
						position="bottom"
						withArrow
						shadow="md"
					>
						<Popover.Target>
							<Button
								onClick={() => setConfirmDelete(true)}
								color="red"
								leftIcon={<IconUserX size={16} />}
							>
								Delete user
							</Button>
						</Popover.Target>
						<Popover.Dropdown>
							<Stack>
								<Text size="sm" align="center">
									Are you sure you want do delete this user?
								</Text>
								<Group position="center">
									<UnstyledButton onClick={() => setConfirmDelete(false)}>
										<Text>No</Text>
									</UnstyledButton>
									<Text>|</Text>
									<UnstyledButton>
										<Text
											color="red"
											onClick={() => handleDelete(selectedUser.id)}
										>
											Yes
										</Text>
									</UnstyledButton>
								</Group>
							</Stack>
						</Popover.Dropdown>
					</Popover>
				</Stack>
			</Drawer>
			<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<div style={{ flexGrow: 1 }}>
					<DataGrid
						sx={{ backgroundColor: "white" }}
						autoHeight
						rows={users}
						columns={columns}
						pageSize={20}
						onRowDoubleClick={(params) => {
							setEditUserModal(true);
							setSelectedUser(params.row);
						}}
					/>
				</div>
				<div style={{ alignSelf: "flex-end" }}>
					<Button
						onClick={() => setNewUserModal(true)}
						leftIcon={<IconPlus size={16} />}
					>
						New User
					</Button>
				</div>
			</div>
		</>
	);
}
