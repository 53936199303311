import React from "react";
import {
	Container,
	Center,
	Card,
	TextInput,
	PasswordInput,
	Stack,
	Button,
	Image,
	Text,
	LoadingOverlay,
	Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuth } from "../contexts/AuthContext";
import logo from "../img/logo.png";

export default function NewPassword() {
	const [error, setError] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const { login, currentUser, newPassword } = useAuth();

	const form = useForm({
		initialValues: {
			password: "",
			repeat_password: "",
		},
	});

	const handleLogin = async (values) => {
		if (values.password !== values.repeat_password) {
			setError("Passwords do not match");
		} else {
			setLoading(true);
			await newPassword(values.password).then(
				async () =>
					await login(currentUser.email, values.password)
						.then((e) => {
							if (e.includes("Error")) {
								setError(e);
								setLoading(false);
							}
						})
						.catch((error) => {
							setError(error);
						})
			);
		}

		/* 		 */
	};

	const date = new Date();

	return (
		<Container style={{ height: "100vh" }}>
			<Center
				style={{
					height: "100vh",
					flexDirection: "column",
					justifyContent: "space-evenly",
				}}
			>
				<Container
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Image
						radius="md"
						src={logo}
						alt="Random unsplash image"
						width={500}
						sx={{ marginBottom: "4rem" }}
					/>

					<Card
						shadow="sm"
						p="lg"
						radius="md"
						withBorder
						sx={{ width: "350px" }}
					>
						<LoadingOverlay visible={loading} overlayBlur={2} />
						<form onSubmit={form.onSubmit((values) => handleLogin(values))}>
							<Stack spacing="lg">
								<Title order={3}>Choose a new password</Title>

								<PasswordInput
									placeholder="Password"
									size="md"
									name="password"
									required
									{...form.getInputProps("password")}
								/>
								<PasswordInput
									placeholder="Repeat Password"
									size="md"
									name="repeat_password"
									required
									{...form.getInputProps("repeat_password")}
								/>
								{error !== "" && (
									<Text color="red" size="sm">
										{error}
									</Text>
								)}
								<Button type="submit" size="md" disabled={loading}>
									Reset & Login
								</Button>
							</Stack>
						</form>
					</Card>
				</Container>
				<Text size="sm" color="gray">
					© {date.getFullYear()} -{" "}
					<Text
						variant="link"
						component="a"
						href="https://www.coachall.be"
						target="_blank"
						rel="noopener noreferrer"
					>
						Coachall BV
					</Text>{" "}
					- All rights reserved
				</Text>
			</Center>
		</Container>
	);
}
