import React from "react";
import { MantineProvider, AppShell } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import Nav from "./components/ui/Navbar";
import { Routes, Route, Link } from "react-router-dom";
import Login from "./components/Login";
import { AuthProvider } from "./contexts/AuthContext";
import { useAuth } from "./contexts/AuthContext";
import { Loader, Container, Center } from "@mantine/core";
import Settings from "./components/Settings";
import Users from "./components/Users";
import NewPassword from "./components/newPassword";
import Automations from "./components/Automations";
import Help from "./components/Help";
import Toc from "./components/Toc";

function Loading() {
	return (
		<Container style={{ height: "100vh" }}>
			<Center
				style={{
					height: "100vh",
					flexDirection: "column",
					justifyContent: "space-evenly",
				}}
			>
				<Loader size="xl" />
			</Center>
		</Container>
	);
}
export default function App() {
	const { currentUser, loading, isAdmin, isFirstLogin } = useAuth();
	return (
		<MantineProvider withGlobalStyles withNormalizeCSS>
			<NotificationsProvider>
				{!currentUser && (
					<Routes>
						{loading && <Route path="/" element={<Loading />} />}
						{!currentUser && <Route path="/" element={<Login />} />}
						<Route path="/help" element={<Help />} />
						<Route path="/terms-of-service" element={<Toc />} />
					</Routes>
				)}
				{currentUser && !isFirstLogin && (
					<AppShell
						padding="md"
						navbar={<Nav />}
						styles={(theme) => ({
							main: {
								backgroundColor:
									theme.colorScheme === "dark"
										? theme.colors.dark[8]
										: theme.colors.gray[0],
							},
						})}
					>
						<Routes>
							<Route path="/" element={<Settings />} />
							<Route path="/automations" element={<Automations />} />
							{isAdmin && <Route path="/users" element={<Users />} />}
							<Route path="/help" element={<Help />} />
							<Route path="/terms-of-service" element={<Toc />} />
						</Routes>
					</AppShell>
				)}
				{currentUser && isFirstLogin && (
					<Routes>
						<Route path="/" element={<NewPassword />} />
						<Route path="/help" element={<Help />} />
						<Route path="/terms-of-service" element={<Toc />} />
					</Routes>
				)}
			</NotificationsProvider>
		</MantineProvider>
	);
}
