import React from "react";
import { IconChevronRight, IconLogout, IconKey } from "@tabler/icons";
import {
	UnstyledButton,
	Group,
	Avatar,
	Text,
	Box,
	useMantineTheme,
	Menu,
} from "@mantine/core";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
export function User() {
	const theme = useMantineTheme();
	const { currentUser, logout } = useAuth();
	const navigate = useNavigate();
	return (
		<Menu shadow="md" width={200} position="top-end">
			<Menu.Target>
				<Box
					sx={{
						paddingTop: theme.spacing.sm,
						borderTop: `1px solid ${
							theme.colorScheme === "dark"
								? theme.colors.dark[4]
								: theme.colors.gray[2]
						}`,
					}}
				>
					<UnstyledButton
						sx={{
							display: "block",
							width: "100%",
							padding: theme.spacing.xs,
							borderRadius: theme.radius.sm,
							color:
								theme.colorScheme === "dark"
									? theme.colors.dark[0]
									: theme.black,

							"&:hover": {
								backgroundColor:
									theme.colorScheme === "dark"
										? theme.colors.dark[6]
										: theme.colors.gray[0],
							},
						}}
						//onClick={() => logout()}
					>
						<Group>
							<Avatar color="cyan" radius="xl">
								{currentUser.displayName.toUpperCase().substr(0, 1)}
							</Avatar>

							<Box sx={{ flex: 1 }}>
								<Text size="sm" weight={500}>
									{currentUser.displayName}
								</Text>
								<Text color="dimmed" size="xs">
									{currentUser.email}
								</Text>
							</Box>

							<IconChevronRight size={18} />
						</Group>
					</UnstyledButton>
				</Box>
			</Menu.Target>
			<Menu.Dropdown>
				{/* 				<Menu.Item icon={<IconKey size={14} />}>Reset password</Menu.Item>
				 */}{" "}
				<Menu.Item
					onClick={() => {
						logout();
						navigate("/");
					}}
					color="red"
					icon={<IconLogout size={14} />}
				>
					Logout
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
