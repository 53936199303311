import React from "react";
import {
	Header,
	Container,
	Accordion,
	Button,
	Divider,
	Title,
	Anchor,
	ThemeIcon,
} from "@mantine/core";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { IconChevronLeft } from "@tabler/icons";
import { IconRefresh } from "@tabler/icons";

export default function Help() {
	let navigate = useNavigate();

	const { currentUser } = useAuth();

	return (
		<>
			{!currentUser && (
				<Header
					height={60}
					p="xs"
					sx={{ display: "flex", alignItems: "center" }}
				>
					<Button
						onClick={() => navigate("/")}
						variant="subtle"
						leftIcon={<IconChevronLeft size={14} />}
					>
						Login
					</Button>
					<Divider orientation="vertical" ml={10} mr={16} />
					<Title order={3}>Knowledgebase</Title>
				</Header>
			)}
			<Container mt={"20px"}>
				<Accordion
					defaultValue="api-key"
					variant="contained"
					sx={{ backgroundColor: "white" }}
				>
					<Accordion.Item value="api-key">
						<Accordion.Control>
							Where can I find my 2Solar API key?
						</Accordion.Control>
						<Accordion.Panel>
							At this time you cannot create an API key yourself. To obtain a
							2Solar API-key contact your account manager or contact 2Solar
							directly at{" "}
							<Anchor href="https://2solar.nl/contact/">
								https://2solar.nl/contact/
							</Anchor>
							. You need to request access to the <b>Quote</b>, <b>Person</b>{" "}
							and <b>Status</b> API endpoints
						</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item value="how-often">
						<Accordion.Control>
							How often do my projects sync?
						</Accordion.Control>
						<Accordion.Panel>
							At this time, our API bridge checks for new content every{" "}
							<b>60 minutes</b>. We may increase or decrease this in the future
							depending on server load.
						</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item value="force-sync">
						<Accordion.Control>Can I force a sync?</Accordion.Control>
						<Accordion.Panel>
							Yes you can! In the{" "}
							<Anchor component={Link} to="/automations">
								automations
							</Anchor>{" "}
							tab of your user environment you can see a sync-icon in the top
							right corner ({" "}
							<ThemeIcon size="xs">
								<IconRefresh variant="filled" />
							</ThemeIcon>{" "}
							). Press it and all active automations will do a forced update.
						</Accordion.Panel>
					</Accordion.Item>

					<Accordion.Item value="no-tl-connection">
						<Accordion.Control>
							My information doesn't appear in Teamleader Focus
						</Accordion.Control>
						<Accordion.Panel>
							Your API-connection to Teamleader might be broken. Try to press
							the "Reconnect" button on the{" "}
							<Anchor component={Link} to="/">
								settings
							</Anchor>{" "}
							page. If the issues still persists please contact{" "}
							<Anchor href="mailto:support@coachall.be" to="/automations">
								support@coachall.be
							</Anchor>{" "}
						</Accordion.Panel>
					</Accordion.Item>
					<Accordion.Item value="no-answer">
						<Accordion.Control>My answer is not listed here</Accordion.Control>
						<Accordion.Panel>
							If you have an issue that is not addressed on this page be sure to
							contact us at{" "}
							<Anchor href="mailto:support@coachall.be" to="/automations">
								support@coachall.be
							</Anchor>{" "}
							and we will get back to you ASAP.
						</Accordion.Panel>
					</Accordion.Item>
				</Accordion>
			</Container>
		</>
	);
}
